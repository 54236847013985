define('@fortawesome/pro-light-svg-icons', ['exports'], (function (exports) { 'use strict';

  const faBadge = {
    prefix: 'fal',
    iconName: 'badge',
    icon: [512, 512, [], "f335", "M256 32c-28.3 0-53 15.9-65.4 39.4c-3.6 6.8-11.5 10.1-18.8 7.8c-25.4-7.8-54.1-1.6-74.1 18.4s-26.2 48.7-18.4 74.1c2.3 7.3-1 15.2-7.8 18.8C47.9 203 32 227.7 32 256s15.9 53 39.4 65.4c6.8 3.6 10.1 11.5 7.8 18.8c-7.8 25.4-1.6 54.1 18.4 74.1s48.7 26.2 74.1 18.4c7.3-2.3 15.2 1 18.8 7.8C203 464.1 227.7 480 256 480s53-15.9 65.4-39.4c3.6-6.8 11.5-10.1 18.8-7.8c25.4 7.8 54.1 1.6 74.1-18.4s26.2-48.7 18.4-74.1c-2.3-7.3 1-15.2 7.8-18.8C464.1 309 480 284.3 480 256s-15.9-53-39.4-65.4c-6.8-3.6-10.1-11.5-7.8-18.8c7.8-25.4 1.6-54.1-18.4-74.1s-48.7-26.2-74.1-18.4c-7.3 2.3-15.2-1-18.8-7.8C309 47.9 284.3 32 256 32zM168.9 45.6C188 18.1 219.9 0 256 0s68 18.1 87.1 45.6c33-6 68.3 3.8 93.9 29.4s35.3 60.9 29.4 93.9C493.9 188 512 219.9 512 256s-18.1 68-45.6 87.1c6 33-3.8 68.3-29.4 93.9s-60.9 35.3-93.9 29.4C324 493.9 292.1 512 256 512s-68-18.1-87.1-45.6c-33 6-68.3-3.8-93.9-29.4s-35.3-60.9-29.4-93.9C18.1 324 0 292.1 0 256s18.1-68 45.6-87.1c-6-33 3.8-68.3 29.4-93.9s60.9-35.3 93.9-29.4z"]
  };
  const faRotateLeft = {
    prefix: 'fal',
    iconName: 'rotate-left',
    icon: [512, 512, ["rotate-back", "rotate-backward", "undo-alt"], "f2ea", "M94.7 360.2c-3.2-5-8.7-8.2-14.7-8.2c-12.3 0-20.3 12.8-13.7 23.2C106 438.2 176.1 480 256 480c123.7 0 224-100.3 224-224S379.7 32 256 32c-56.1 0-107.4 20.6-146.7 54.7L78.6 56c-5.1-5.1-12.1-8-19.3-8C44.2 48 32 60.2 32 75.3L32 176c0 8.8 7.2 16 16 16l100.7 0c15.1 0 27.3-12.2 27.3-27.3c0-7.2-2.9-14.2-8-19.3l-36-36C165.5 81.1 208.7 64 256 64c106 0 192 86 192 192s-86 192-192 192c-67.6 0-127.1-35-161.3-87.8zM64 86.6L137.4 160 64 160l0-73.4z"]
  };
  const faQuestion = {
    prefix: 'fal',
    iconName: 'question',
    icon: [320, 512, [10067, 10068, 61736], "3f", "M144 64c-53 0-96 43-96 96c0 8.8-7.2 16-16 16s-16-7.2-16-16C16 89.3 73.3 32 144 32l32 0c70.7 0 128 57.3 128 128c0 35.3-16.6 68.4-44.8 89.6l-60.8 45.6C184.3 305.8 176 322.4 176 340l0 12c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-12c0-27.7 13-53.8 35.2-70.4L240 224c20.1-15.1 32-38.8 32-64c0-53-43-96-96-96l-32 0zm-8 384a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"]
  };
  const faBadgeDollar = {
    prefix: 'fal',
    iconName: 'badge-dollar',
    icon: [512, 512, [], "f645", "M190.6 71.4C203 47.9 227.7 32 256 32s53 15.9 65.4 39.4c3.6 6.8 11.5 10.1 18.8 7.8c25.4-7.8 54.1-1.6 74.1 18.4s26.2 48.7 18.4 74.1c-2.3 7.3 1 15.2 7.8 18.8C464.1 203 480 227.7 480 256s-15.9 53-39.4 65.4c-6.8 3.6-10.1 11.5-7.8 18.8c7.8 25.4 1.6 54.1-18.4 74.1s-48.7 26.2-74.1 18.4c-7.3-2.3-15.2 1-18.8 7.8C309 464.1 284.3 480 256 480s-53-15.9-65.4-39.4c-3.6-6.8-11.5-10.1-18.8-7.8c-25.4 7.8-54.1 1.6-74.1-18.4s-26.2-48.7-18.4-74.1c2.3-7.3-1-15.2-7.8-18.8C47.9 309 32 284.3 32 256s15.9-53 39.4-65.4c6.8-3.6 10.1-11.5 7.8-18.8c-7.8-25.4-1.6-54.1 18.4-74.1s48.7-26.2 74.1-18.4c7.3 2.3 15.2-1 18.8-7.8zM256 0c-36.1 0-68 18.1-87.1 45.6c-33-6-68.3 3.8-93.9 29.4s-35.3 60.9-29.4 93.9C18.1 188 0 219.9 0 256s18.1 68 45.6 87.1c-6 33 3.8 68.3 29.4 93.9s60.9 35.3 93.9 29.4C188 493.9 219.9 512 256 512s68-18.1 87.1-45.6c33 6 68.3-3.8 93.9-29.4s35.3-60.9 29.4-93.9C493.9 324 512 292.1 512 256s-18.1-68-45.6-87.1c6-33-3.8-68.3-29.4-93.9s-60.9-35.3-93.9-29.4C324 18.1 292.1 0 256 0zm16.9 140.4c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 20.8c-9.5 1.5-19.2 4.6-27.7 9.7c-14.1 8.3-25.7 22.7-25.6 43.4c.1 19.6 11.4 32.1 24.4 39.9c11.5 6.9 26.1 11.3 38.4 15l1.7 .5c13.8 4.2 24.8 7.7 32.7 12.6c6.9 4.3 9.3 8.4 9.4 14.3c.1 8.1-3.4 13.2-9.5 17c-6.8 4.3-16.9 6.5-27.3 6.2c-13.4-.4-25.8-4.7-40.9-9.8c0 0 0 0 0 0s0 0 0 0c-2.5-.9-5.2-1.8-7.9-2.7c-8.4-2.8-17.4 1.7-20.2 10.1s1.7 17.4 10.1 20.2c2.2 .7 4.6 1.5 6.9 2.4c10.6 3.7 22.7 7.8 35.4 10.1l0 21.3c0 8.8 7.2 16 16 16s16-7.2 16-16l0-20.5c10-1.5 20-4.6 28.7-10.1c14.3-8.9 24.9-23.9 24.6-44.6c-.3-19.8-11.3-32.7-24.4-41c-12-7.6-27.4-12.2-40-16l-.4-.1c-13.9-4.2-25-7.6-32.8-12.3c-6.9-4.2-8.9-7.8-8.9-12.7c0-6.8 3.2-11.8 9.9-15.7c7.3-4.3 17.5-6.5 27.1-6.4c11.4 .2 23.7 2.7 36.1 6c8.5 2.3 17.3-2.8 19.6-11.3s-2.8-17.3-11.3-19.6c-8.7-2.3-18.3-4.5-28.1-5.8l0-20.8z"]
  };
  const faSliders = {
    prefix: 'fal',
    iconName: 'sliders',
    icon: [512, 512, ["sliders-h"], "f1de", "M0 416c0 8.8 7.2 16 16 16l65.6 0c7.4 36.5 39.7 64 78.4 64s71-27.5 78.4-64L496 432c8.8 0 16-7.2 16-16s-7.2-16-16-16l-257.6 0c-7.4-36.5-39.7-64-78.4-64s-71 27.5-78.4 64L16 400c-8.8 0-16 7.2-16 16zm112 0a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM304 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm48-80c-38.7 0-71 27.5-78.4 64L16 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l257.6 0c7.4 36.5 39.7 64 78.4 64s71-27.5 78.4-64l65.6 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-65.6 0c-7.4-36.5-39.7-64-78.4-64zM192 144a48 48 0 1 1 0-96 48 48 0 1 1 0 96zm78.4-64C263 43.5 230.7 16 192 16s-71 27.5-78.4 64L16 80C7.2 80 0 87.2 0 96s7.2 16 16 16l97.6 0c7.4 36.5 39.7 64 78.4 64s71-27.5 78.4-64L496 112c8.8 0 16-7.2 16-16s-7.2-16-16-16L270.4 80z"]
  };
  const faChevronRight = {
    prefix: 'fal',
    iconName: 'chevron-right',
    icon: [320, 512, [9002], "f054", "M299.3 244.7c6.2 6.2 6.2 16.4 0 22.6l-192 192c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L265.4 256 84.7 75.3c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0l192 192z"]
  };
  const faPersonSeat = {
    prefix: 'fal',
    iconName: 'person-seat',
    icon: [384, 512, [], "e21e", "M176 88a24 24 0 1 1 -48 0 24 24 0 1 1 48 0zM152 32a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM32 144c0-8.8-7.2-16-16-16s-16 7.2-16 16L0 336c0 61.9 50.1 112 112 112l96 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-96 0c-44.2 0-80-35.8-80-80l0-192zm116 64c15.5 0 28 12.5 28 28l0 4 0 88-40 0c-8.8 0-16-7.2-16-16l0-76c0-15.5 12.5-28 28-28zm58.8 16c-5.6-27.4-29.8-48-58.8-48c-33.1 0-60 26.9-60 60l0 76c0 26.5 21.5 48 48 48l56 0 40.6 0c15.3 0 28.4 10.8 31.4 25.7l16.3 81.4c1.5 7.5 8.1 12.9 15.7 12.9l72 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-58.9 0-13.7-68.6c-6-29.9-32.2-51.4-62.8-51.4L208 328l0-72 64 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-65.2 0z"]
  };
  const faCheck = {
    prefix: 'fal',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z"]
  };
  const faTriangleExclamation = {
    prefix: 'fal',
    iconName: 'triangle-exclamation',
    icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M34.5 420.4c-1.6 2.8-2.5 6-2.5 9.3c0 10.2 8.2 18.4 18.4 18.4l411.2 0c10.2 0 18.4-8.2 18.4-18.4c0-3.3-.9-6.4-2.5-9.3L276.5 75.8C272.2 68.5 264.4 64 256 64s-16.2 4.5-20.5 11.8L34.5 420.4zM6.9 404.2l201-344.6C217.9 42.5 236.2 32 256 32s38.1 10.5 48.1 27.6l201 344.6c4.5 7.7 6.9 16.5 6.9 25.4c0 27.8-22.6 50.4-50.4 50.4L50.4 480C22.6 480 0 457.4 0 429.6c0-8.9 2.4-17.7 6.9-25.4zM256 160c8.8 0 16 7.2 16 16l0 128c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zM232 384a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"]
  };
  const faExclamationTriangle = faTriangleExclamation;

  exports.faBadge = faBadge;
  exports.faBadgeDollar = faBadgeDollar;
  exports.faCheck = faCheck;
  exports.faChevronRight = faChevronRight;
  exports.faExclamationTriangle = faExclamationTriangle;
  exports.faPersonSeat = faPersonSeat;
  exports.faQuestion = faQuestion;
  exports.faRotateLeft = faRotateLeft;
  exports.faSliders = faSliders;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
